<template>
  <flex fill class="height-100">
    <iopr-header class="p-20">
      <template #icon>
        <icon name="iopr" :size="32" class="font-color-primary" />
      </template>
      <template #title>
        <span class="font-uppercase font-bold font-size-20 ml-20">
          иопр пк
        </span>
      </template>
    </iopr-header>
    <flex fill class="px-20 py-15 overflow-auto">
      <form v-if="!rsaaURL">
        <uiInput
          class="mb-20"
          large
          autocomplete="off"
          :disabled="loading"
          type="text"
          placeholder="Введите адрес электронной почты"
          v-model="userData.email"
        />
        <uiInput
          class="mb-20"
          large
          autocomplete="off"
          :disabled="loading"
          type="password"
          placeholder="Введите пароль"
          v-model="userData.password"
        />
        <uiButton
          primary
          :loading="loading"
          :disabled="loading"
          class="width-100 font-uppercase"
          @click.prevent="login(userData)"
        >
          войти
        </uiButton>
      </form>
      <flex v-else column>
        <div class="font-color-gray mb-30">
          Для работы в системе необходимо авторизоваться через Региональный
          сервис аутентификации и авторизации (РСАА)
        </div>
        <uiButton primary class="width-100 font-uppercase" @click="toRsaaLogin">
          авторизация через рсаа
        </uiButton>
        <div class="font-color-gray mt-30">
          Ели у вас нет учетных данных для работы в системе, вам необходимо
          <a
            href="http://office.permkrai.ru/lk/register"
            class="font-color-primary text-decoration-none"
          >
            подать заявку в службу технической поддержки РСАА
          </a>
        </div>
      </flex>
      <flex column class="email">
        <span class="font-color-gray">Техническая поддержка ИОПР ПК:</span>
        <a
          href="mailto:support_iopr@permkrai.ru"
          class="font-color-primary text-decoration-none"
        >
          support_iopr@permkrai.ru
        </a>
      </flex>
    </flex>
  </flex>
</template>

<script>
import { reactive } from 'vue';
import useAuth from '@/composition/useAuth.js';

export default {
  setup() {
    const userData = reactive({
      email: '',
      password: ''
    });

    const { login, loading } = useAuth();

    const rsaaURL = process.env.VUE_APP_RSAA_LOGIN_URL;

    const toRsaaLogin = () => {
      window.location = rsaaURL;
    };

    return {
      userData,
      loading,
      login,
      rsaaURL,
      toRsaaLogin
    };
  }
};
</script>

<style lang="scss" scoped>
.email {
  margin-top: 70px;
}
</style>
